export const animals = [
  "aardvark",
  "albatross",
  "alligator",
  "alpaca",
  "ant",
  "anteater",
  "antelope",
  "ape",
  "armadillo",
  "donkey",
  "baboon",
  "badger",
  "barracuda",
  "bat",
  "bear",
  "beaver",
  "bee",
  "bison",
  "boar",
  "buffalo",
  "butterfly",
  "camel",
  "capybara",
  "caribou",
  "cassowary",
  "cat",
  "caterpillar",
  "cattle",
  "chamois",
  "cheetah",
  "chicken",
  "chimpanzee",
  "chinchilla",
  "chough",
  "clam",
  "cobra",
  "cockroach",
  "cod",
  "cormorant",
  "coyote",
  "crab",
  "crane",
  "crocodile",
  "crow",
  "curlew",
  "deer",
  "dinosaur",
  "dingo",
  "dodo",
  "dog",
  "dogfish",
  "dolphin",
  "dotterel",
  "dove",
  "dragonfly",
  "duck",
  "dugong",
  "dunlin",
  "eagle",
  "echidna",
  "eel",
  "eland",
  "elephant",
  "elk",
  "emu",
  "falcon",
  "ferret",
  "finch",
  "fish",
  "flamingo",
  "fly",
  "fox",
  "frog",
  "gaur",
  "gazelle",
  "gerbil",
  "giraffe",
  "gnat",
  "gnu",
  "goat",
  "goldfinch",
  "goldfish",
  "goose",
  "gorilla",
  "goshawk",
  "grasshopper",
  "grouse",
  "guanaco",
  "gull",
  "hamster",
  "hare",
  "hawk",
  "hedgehog",
  "heron",
  "herring",
  "hippopotamus",
  "hornet",
  "horse",
  "human",
  "hummingbird",
  "hyena",
  "ibex",
  "ibis",
  "jackal",
  "jaguar",
  "jay",
  "jellyfish",
  "kangaroo",
  "kingfisher",
  "koala",
  "kookabura",
  "kouprey",
  "kudu",
  "lapwing",
  "lark",
  "lemur",
  "leopard",
  "lion",
  "llama",
  "lobster",
  "locust",
  "loris",
  "louse",
  "lyrebird",
  "lynx",
  "magpie",
  "mallard",
  "manatee",
  "mandrill",
  "mantis",
  "marten",
  "meerkat",
  "mink",
  "mole",
  "mongoose",
  "monkey",
  "moose",
  "mosquito",
  "moth",
  "mouse",
  "mule",
  "narwhal",
  "newt",
  "nightingale",
  "octopus",
  "okapi",
  "opossum",
  "orangutan",
  "oryx",
  "ostrich",
  "otter",
  "owl",
  "oyster",
  "panther",
  "parrot",
  "partridge",
  "peafowl",
  "pelican",
  "penguin",
  "pheasant",
  "pig",
  "pigeon",
  "pony",
  "porcupine",
  "porpoise",
  "puma",
  "quail",
  "quelea",
  "quetzal",
  "rabbit",
  "raccoon",
  "rail",
  "ram",
  "rat",
  "raven",
  "red-deer",
  "red-panda",
  "reindeer",
  "rhinoceros",
  "rook",
  "salamander",
  "salmon",
  "sand-dollar",
  "sandpiper",
  "sardine",
  "scorpion",
  "seahorse",
  "seal",
  "shark",
  "sheep",
  "shrew",
  "skunk",
  "snail",
  "snake",
  "sparrow",
  "spider",
  "spoonbill",
  "squid",
  "squirrel",
  "starling",
  "stingray",
  "stinkbug",
  "stork",
  "swallow",
  "swan",
  "tapir",
  "tarsier",
  "termite",
  "tiger",
  "toad",
  "tortoise",
  "trout",
  "turkey",
  "turtle",
  "viper",
  "vulture",
  "wallaby",
  "walrus",
  "wasp",
  "weasel",
  "whale",
  "wild-boar",
  "wildcat",
  "wolf",
  "wolverine",
  "wombat",
  "woodcock",
  "woodpecker",
  "worm",
  "wren",
  "x-ray",
  "yak",
  "zebra"
];
