import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const AnswerInput = ({ letter, setAnsValue }) => {
  const [ans, setAns] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleOnSubmit = (e) => {
    e.preventDefault();

    setErrorMessage('');

    if (ans.trim() === '' || ans.trim().substring(0,1).toUpperCase() !== letter) {
      setErrorMessage(`Oops! Enter something that starts with the letter ${letter}!`);
      setAns('');
    }
    else {
      setAnsValue(ans.trim().replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '-').toLowerCase());
      setAns('');
    }
  };

  const handleTextOnChange = (e) => {
    setAns((e.target.value));
  };

  return (
    <div className="answerForm">
      <form onSubmit={handleOnSubmit}>
        <TextField
          name="ansValue"
          label="Answer"
          variant="filled"
          value={ans}
          onChange={e => handleTextOnChange(e)}
        />

        {errorMessage &&
        <p style={{padding: '1em', fontSize: '1.5em', backgroundColor: '#ff6464', color: '#fff', zIndex: '1'}}>{errorMessage}</p>}

        <Button
          type="submit"
          variant="contained"
          className="main-button"
        >
          Enter
        </Button>
      </form>
    </div>
  )
}

export default AnswerInput;
