import { useState, useEffect } from 'react';

const Countdown = ({ fromNumber }) => {
  const [counter, setCounter] = useState(fromNumber);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    // setCounter(1);
  });

  return (
    <div className={`overlay${counter === 0 ? 'hidden' : ''}`}>
      <h2 className="countdownCounterCheer">{counter > 2 ? 'Ready' : counter > 1 ? 'Set' : 'Go!'}</h2>
      <h1 className="countdownCounter">{counter}</h1>
    </div>
  )
}

export default Countdown;
