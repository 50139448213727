import {
  LETTER_PICK,
  CATEGORY_PICK,
  ANSWER_ADD,
  CLEAR_ALL,
  ANSPOOL_ADD,
  ANSPOOL_CLEAR
} from './constants';
import { initialState } from '../utils/utils';

export const quizReducer = (state, action) => {
  switch (action.type) {
    case LETTER_PICK:
      return {
          ...state,
          letter: action.payload
        };
    case CATEGORY_PICK:
      return {
        ...state,
        categories: action.payload
      };
    case ANSWER_ADD:
      return {
          ...state,
          answers: [...state.answers, action.payload]
        };
    case CLEAR_ALL:
      return initialState;
    case ANSPOOL_ADD:
      return [...state, [action.payload]];
    case ANSPOOL_CLEAR:
      return [];
    default:
      return state;
  }
};
