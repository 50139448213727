export const musicalInstruments = [
  "accordian",
	"air-horn",
  "angklung",
	"baby-grand-piano",
	"bagpipe",
	"banjo",
  "bass",
	"bass-guitar",
	"bassoon",
  "bell",
	"bugle",
	"calliope",
	"cello",
	"clarinet",
	"clavichord",
	"concertina",
  "cymbal",
	"didgeridoo",
	"dobro",
  "drum",
	"dulcimer",
	"fiddle",
	"fife",
	"flugelhorn",
	"flute",
	"french-horn",
  "gendang",
	"glockenspiel",
	"grand-piano",
	"guitar",
  "hand-bell",
	"harmonica",
	"harp",
	"harpsichord",
	"hurdy-gurdy",
	"kazoo",
  "keyboard",
  "kendang",
	"kick-drum",
  "koto",
	"lute",
	"lyre",
	"mandolin",
  "maraca",
  "maracas",
	"marimba",
	"mellotran",
	"melodica",
	"oboe",
	"pan-flute",
	"piano",
	"piccolo",
	"pipe-organ",
	"saxaphone",
  "shamisen",
	"sitar",
	"sousaphone",
	"tambourine",
	"theremin",
  "tifa",
  "timpani",
	"trombone",
  "triangle",
	"tuba",
	"ukulele",
	"viola",
	"violin",
	"vuvuzela",
	"washtub-bass",
	"xylophone",
	"zither"
];