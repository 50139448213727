import {
  createContext,
  useReducer,
  useEffect
} from 'react';
import { quizReducer } from '../reducers/quizReducer';
import { initialState } from '../utils/utils';

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const [store, dispatch] = useReducer(quizReducer, initialState);

  useEffect(() => {
    localStorage.setItem('store', JSON.stringify(store));
  }, [store]);

  return (
    <QuizContext.Provider value={{store, dispatch}}>
      {children}
    </QuizContext.Provider>
  );
};
