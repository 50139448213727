import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import { animals } from '../utils/animalsData';
import { fruitVegie } from '../utils/fruitVegieData';
import { sports } from '../utils/sportsData';
import { musicalInstruments } from '../utils/musicalInstrumentsData';
import { gameRound } from '../utils/utils';
import { QuizContext } from '../contexts/QuizContext';
import { CLEAR_ALL } from '../reducers/constants';

const TotalScore = () => {
  const { store, dispatch } = useContext(QuizContext);
  const { categories, answers } = store;
  const [totalScore, setTotalScore] = useState([]);
  const history = useHistory();

  const handleRestart = () => {
    dispatch({type: CLEAR_ALL});
    history.push('/');
  };

  useEffect(() => {
    function calculateScore(categories, answers) {
      let score = 0;
      let responses = [];

      categories.map((category, i) => {
        switch (category) {
          case "Country":
            responses.push(axios.get(`https://restcountries.com/v3.1/name/${answers[i]}`).catch(err => console.log(err)));
            break;
          case "Capital City":
            responses.push(axios.get(`https://restcountries.com/v3.1/capital/${answers[i]}`).catch(err => console.log(err)));
            break;
          case "Animal":
            if (animals.includes(answers[i])) {
              setTotalScore(score => Number(score) + 10);
            } else {
              setTotalScore(score => Number(score) + 0);
            }
            break;
          case "Fruit Veggie":
            if (fruitVegie.includes(answers[i])) {
              setTotalScore(score => Number(score) + 10);
            } else {
              setTotalScore(score => Number(score) + 0);
            }
            break;
          case "Movie Title":
            responses.push(axios.get(`https://www.omdbapi.com/?t=${answers[i]}&apikey=f4bbef48`).catch(err => console.log(err)));
            break;
          case "Colour Name":
            responses.push(axios.get(`https://api.color.pizza/v1/names/${answers[i]}`).catch(err => console.log(err)));
            break;
          case "Sports":
            if (sports.includes(answers[i])) {
              setTotalScore(score => Number(score) + 10);
            } else {
              setTotalScore(score => Number(score) + 0);
            }
            break;
          case "Musical Instrument":
            if (musicalInstruments.includes(answers[i])) {
              setTotalScore(score => Number(score) + 10);
            } else {
              setTotalScore(score => Number(score) + 0);
            }
            break;
          default:
            setTotalScore(score => Number(score) + 0);
            break;
        }
      });

      Promise.all(responses).then(res => res.map(r => {
        if (typeof(r) === 'undefined' || Object.keys(r).includes('data') !== true) {
          setTotalScore(score => Number(score) + 0);
        }
        else if (typeof(r.data) === 'undefined') {
          setTotalScore(score => Number(score) + 0);
        }
        else {
          if (typeof(r.data) === 'object' && typeof(r.data.length) === 'undefined') {
            if (Object.keys(r.data).includes('Title')) {
              setTotalScore(score => Number(score) + 10);
            }
            else if (Object.keys(r.data).includes('Error')) {
              setTotalScore(score => Number(score) + 0);
            }
            else if (Object.keys(r.data).includes('colors')) {
              if (r.data.colors.length === 0) {
                setTotalScore(score => Number(score) + 0);
              }
              else if (r.data.colors.length > 0) {
                setTotalScore(score => Number(score) + 10);
              }
            }
          }
          else if (typeof(r.data) === 'object' && typeof(r.data.length) !== 'undefined') {
            if (r.data.length > 0 && Object.keys(r.data[0]).includes('name')) {
              setTotalScore(score => Number(score) + 10);
            }
          }
          else {
            setTotalScore(score => Number(score) + 10);
          }
        }
      }));

      return score;
    }

    calculateScore(categories, answers);
  },[]);

  return (
    <div className="totalScore">
      <h2 style={{marginBottom: '.5em'}}>Your Answers</h2>

      {categories.map((category, i) => (
        <span className="answer-list" key={i}>{category}: {answers[i]}</span>
      ))}

      <h2>Total Score: <br />{totalScore > gameRound * 10 ? gameRound * 10 : totalScore } / {gameRound * 10}</h2>

      <Button
        variant="contained"
        className="main-button"
        onClick={handleRestart}
      >
        Restart
      </Button>
    </div>
  )
}

export default TotalScore;
