export const fruitVegie = [
  "acorn-squash",
  "alfalfa-sprout",
  "amaranth",
  "anise",
  "artichoke",
  "arugula",
  "asparagus",
  "aubergine",
  "azuki-bean",
  "banana-squash",
  "basil",
  "bean-sprout",
  "beet",
  "black-bean",
  "black-eyed-pea",
  "bok-choy",
  "borlotti-bean",
  "broad-beans",
  "broccoflower",
  "broccoli",
  "brussels-sprout",
  "butternut-squash",
  "cabbage",
  "calabrese",
  "caraway",
  "carrot",
  "cauliflower",
  "cayenne-pepper",
  "celeriac",
  "celery",
  "chamomile",
  "chard",
  "chayote",
  "chickpea",
  "chinese-cabbage",
  "chives",
  "choy-sum",
  "cilantro",
  "collard-green",
  "corn",
  "corn-salad",
  "courgette",
  "cucumber",
  "daikon",
  "delicata",
  "dill",
  "duku",
  "durian",
  "eggplant",
  "endive",
  "fennel",
  "fiddlehead",
  "frisee",
  "gai-lan",
  "garlic",
  "gem-squash",
  "ginger",
  "green-bean",
  "green-pepper",
  "habanero",
  "herbs-and-spice",
  "horseradish",
  "hubbard-squash",
  "jalapeno",
  "jerusalem-artichoke",
  "jicama",
  "kale",
  "kidney-bean",
  "kohlrabi",
  "lavender",
  "leek",
  "legume",
  "lemon-grass",
  "lentils",
  "lettuce",
  "lima-bean",
  "mamey",
  "mangetout",
  "mangosteen",
  "marjoram",
  "mung-bean",
  "mushroom",
  "mustard-green",
  "navy-bean",
  "new-zealand-spinach",
  "nopale",
  "okra",
  "onion",
  "oregano",
  "paprika",
  "pak-choy",
  "parsley",
  "parsnip",
  "patty-pan",
  "pea",
  "petai",
  "pinto-bean",
  "potato",
  "pumpkin",
  "radicchio",
  "radish",
  "rambutan",
  "rhubarb",
  "rosemary",
  "runner-bean",
  "rutabaga",
  "sage",
  "scallion",
  "shallot",
  "skirret",
  "snap-pea",
  "soy-bean",
  "spaghetti-squash",
  "spinach",
  "squash",
  "sweet-potato",
  "tabasco-pepper",
  "taro",
  "tat-soi",
  "thyme",
  "topinambur",
  "tubers",
  "turnip",
  "wasabi",
  "water-chestnut",
  "watercress",
  "white-radish",
  "wombok",
  "yam",
  "zucchini",
  "apple",
  "apricot",
  "avocado",
  "banana",
  "bell-pepper",
  "bilberry",
  "bitter-melon",
  "blackberry",
  "blackcurrant",
  "blood-orange",
  "blueberry",
  "boysenberry",
  "breadfruit",
  "canary-melon",
  "cantaloupe",
  "cherimoya",
  "cherry",
  "chili-pepper",
  "chokos",
  "clementine",
  "cloudberry",
  "coconut",
  "cranberry",
  "cucumber",
  "currant",
  "damson",
  "date",
  "dragonfruit",
  "durian",
  "eggplant",
  "elderberry",
  "feijoa",
  "fig",
  "goji-berry",
  "gooseberry",
  "grape",
  "grapefruit",
  "guava",
  "hairy-melon",
  "honeydew",
  "huckleberry",
  "jackfruit",
  "jambul",
  "jengkol",
  "jujube",
  "kiwi-fruit",
  "kiwi",
  "kumquat",
  "lemon",
  "lime",
  "loquat",
  "longan",
  "long-melon",
  "lychee",
  "mandarine",
  "mango",
  "mulberry",
  "nashi-pear",
  "nectarine",
  "nut",
  "olive",
  "orange",
  "papaya",
  "passionfruit",
  "peach",
  "pear",
  "persimmon",
  "physalis",
  "pineapple",
  "plum",
  "pomegranate",
  "pomelo",
  "purple-mangosteen",
  "quince",
  "raisin",
  "rambutan",
  "raspberry",
  "redcurrant",
  "rock-melon",
  "salal-berry",
  "sapodilla",
  "satsuma",
  "srikaya",
  "starfruit",
  "strawberry",
  "tamarillo",
  "tangerine",
  "tomato",
  "ugli-fruit",
  "watermelon",
  "wintermelon"
];
