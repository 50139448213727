import React, { useContext, useState, useEffect } from 'react';

import { gameRound } from '../utils/utils';
import Countdown from '../components/Countdown';
import AnimatedBg from '../components/AnimatedBg';
import SetQA from '../components/SetQA';
import TotalScore from '../components/TotalScore';

import {
  ANSWER_ADD
} from '../reducers/constants';
import { QuizContext } from '../contexts/QuizContext';

const SessionPage = () => {
  const { store, dispatch } = useContext(QuizContext);
  const { letter, categories, answers } = store;
  const [round, setRound] = useState(0);

  const setAnsValue = (ans) => {
    dispatch({type: ANSWER_ADD, payload: ans})
  };

  useEffect(() => {
    setRound(answers.length);
  }, [answers]);

  return (
    <div className="defaultPage squares">
      <AnimatedBg />
      <div className="sessionPage">
        <Countdown fromNumber={3} />

        <h1>{round < gameRound ? "Game On" : "Results"}</h1>
        <div className="letter">
          {letter}
        </div>

        {round < gameRound
          ? <SetQA letter={letter} category={categories[round]} setAnsValue={setAnsValue} />
          : <TotalScore />
        }
      </div>
    </div>
  )
}

export default SessionPage;
