import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import AnimatedBg from '../components/AnimatedBg';
import { gameRound, randomiseAlphabet, randomiseCategory } from '../utils/utils';

import {
  LETTER_PICK,
  CATEGORY_PICK
} from '../reducers/constants';
import { QuizContext } from '../contexts/QuizContext';

const LetterPage = () => {
  const { store, dispatch } = useContext(QuizContext);
  const { letter } = store;
  
  const getRandomCategories = () => {
    let category = randomiseCategory();
    let randomCategories = [];
    let i = 0;

    while (i < gameRound) {
      if (!randomCategories.includes(category)) {
        randomCategories.push(category);
        i++;
      }
      category = randomiseCategory();
    }

    return randomCategories;
  };

  useEffect(() => {
    dispatch({type: LETTER_PICK, payload: randomiseAlphabet().toUpperCase()});

    dispatch({type: CATEGORY_PICK, payload: getRandomCategories()});
  }, [dispatch]);

  return (
    <div className="defaultPage squares">
      <AnimatedBg />
      <div className="letterPage">
        <h1>Your Letter is</h1>
        <div className="letter">
          {letter}
        </div>
        <p>Think of something that starts with letter {letter} from some categories.</p>
        <Button
          component={Link}
          variant="contained"
          className="main-button"
          to={{
            pathname: `/letter/${letter}`
          }}
        >
          Go!
        </Button>
      </div>
    </div>
  )
}

export default LetterPage;
