import { Link } from 'react-router-dom';

const Header = ({ children }) => {
  return (
    <>
      <header>
        <div id="logo">
          <Link to="/">AZ</Link>
        </div>
      </header>
      {children}
    </>
  )
}

export default Header;
