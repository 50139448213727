import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div>
        <span>A-Z Quiz &copy; {new Date().getFullYear()}. Jessica Gozali.</span>
      </div>
    </footer>
  )
}

export default Footer;
