import React from 'react';
// import { capsFirst } from '../utils/utils';

const Category = ({ letter, category }) => {
  return (
    <>
      <p>{category} that starts with the letter {letter}:</p>
    </>
  )
}

export default Category;
