import './styles/index.scss';

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { QuizProvider } from './contexts/QuizContext';

import Header from './components/Header';
import Footer from './components/Footer';
import WelcomePage from './pages/WelcomePage';
import LetterPage from './pages/LetterPage';
import SessionPage from './pages/SessionPage';

const App = () => {
  return (
    <div className="App">
      <Header />
      {/* Routing */}
      <Switch>
        <QuizProvider>
          <Route exact path="/" component={WelcomePage} />
          <Route exact path="/letter" component={LetterPage} />
          <Route exact path="/letter/:letter" component={SessionPage} />
        </QuizProvider>
      </Switch>
      <Footer />
    </div>
  )
}

export default App;
