import React from 'react';
import Category from './Category';
import AnswerInput from './AnswerInput';

const SetQA = ({ letter, category, setAnsValue }) => {
  return (
    <div>
      <Category letter={letter} category={category} />
      <AnswerInput letter={letter} setAnsValue={setAnsValue} />
    </div>
  )
}

export default SetQA;
