export const sports = [
  "aerobics",
  "aikido",
  "american-football",
  "archery",
  "badminton",
  "baseball",
  "basketball",
  "bobsleighing",
  "bowling",
  "boxing",
  "bungee-jumping",
  "canoeing",
  "car-racing",
  "cricket",
  "curling",
  "cycling",
  "darts",
  "discus-throw",
  "dodgeball",
  "equestrianism",
  "fencing",
  "figure-skating",
  "fishing",
  "football",
  "golf",
  "gymnastics",
  "handball",
  "hang-gliding",
  "hiking",
  "hockey",
  "horse-racing",
  "horse-riding",
  "ice-hockey",
  "ice-skating",
  "ice-skate",
  "javelin",
  "jet-skiing",
  "judo",
  "karate",
  "kayaking",
  "kickball",
  "kickboxing",
  "kitesurfing",
  "lacrosse",
  "long-iump",
  "martial-arts",
  "mountain-climbing",
  "olympic-swimming",
  "paragliding",
  "parasailing",
  "pencak-silat",
  "pole-vault",
  "polo",
  "pool",
  "powerlifting",
  "racquetball",
  "rock-climbing",
  "roller-skating",
  "rounders",
  "rowing",
  "rugby",
  "running",
  "scuba-diving",
  "skateboarding",
  "ski",
  "skiing",
  "sky-diving",
  "skydiving",
  "snorkeling",
  "snowboarding",
  "soccer",
  "squash",
  "sumo",
  "sumo-wrestling",
  "surfing",
  "swimming",
  "table-tennis",
  "taekwondo",
  "tennis",
  "volley",
  "volleyball",
  "water-polo",
  "water-skiing",
  "weight-lifting",
  "wind-surfing",
  "wrestling"
];