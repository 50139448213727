const gameRound = 3;

const initialState = {
  letter: '',
  categories: [],
  answers: []
};

const availableCategories = [
  "Country",
  "Capital City",
  "Animal",
  "Fruit Veggie",
  "Movie Title",
  "Colour Name",
  "Sports",
  "Musical Instrument"
];

const randomiseAlphabet = () => {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
};

const randomiseCategory = () => {
  const max = availableCategories.length;
  return availableCategories[Math.floor(Math.random() * (max))];
};

// Capitalise first letter of each word
const capsFirst = (sentence) => {
  const words = sentence.split(" ");
  let capsWords = '';

  words.map((word) => (
    capsWords += word[0].toUpperCase() + word.substring(1) + " "
  ));

  return capsWords.trim();
};

export {
  gameRound,
  initialState,
  randomiseAlphabet,
  randomiseCategory,
  capsFirst
};
