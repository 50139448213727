import React, { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import AnimatedBg from '../components/AnimatedBg';
import { initialState } from '../utils/utils';
import { CLEAR_ALL } from '../reducers/constants';
import { QuizContext } from '../contexts/QuizContext';

const WelcomePage = () => {
  const { dispatch } = useContext(QuizContext);

  useEffect(() => {
    localStorage.setItem('store', JSON.stringify(initialState));
    dispatch({type: CLEAR_ALL});
  }, [dispatch]);

  return (
    <div className="defaultPage squares">
      <AnimatedBg />
      <div className="welcomePage">
        <h1>Welcome to A-Z Quiz!</h1>
        <p>Collect your memory of things from A to Z. You will be asked to enter words from certain categories that start with the letter given to you!</p>
        <p>Click the Start Game button to start playing.</p>
        <Button
          component={Link}
          variant="contained"
          className="main-button"
          to="/letter"
        >
          Start Game
        </Button>
      </div>
    </div>
  )
}

export default WelcomePage;
